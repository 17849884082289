'use client'

import { useTranslations } from 'next-intl'
import { useMemo } from 'react'
import styled from 'styled-components'

import { Box, ContentText, fontWeights } from '@fortum/elemental-ui'

import { useContractTemplateCostCalculator } from '@/open-web/hooks/useContractTemplateCostCalculator/useContractTemplateCostCalculator'
import type { CostDetails } from '@/open-web/services/calculators/calculateContractTemplateCost'
import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import { countryConfig } from '@/shared/countryConfig'
import { useTheme } from '@/shared/hooks/useTheme'
import { useGlobalStore } from '@/shared/store/provider'
import type { CustomerType } from '@/shared/store/slices/selectedContractSlice'

import { getSelectedAddonsTariffNos } from '../PriceBreakdown/utils'
import { PriceBreakdownInfoIcon } from '../PriceBreakdownInfoIcon'
import type { PriceProps } from './types'
import { defineTheme, validateCustomerType } from './utils'

/**
 * Price Component
 *
 * Needed to show price in all cases, including
 * price fetcing and styling.
 *
 * By default, renders:
 * - estimated monthly cost with applied discounts
 * - estimated monthly cost without discounts
 * - electricity price
 */
export const Price: React.FC<PriceProps> = ({
  contractTemplate,
  campaignConfiguration,
  contractProduct,
  mode = 'full',
  theme = 'light',
  priceBreakdownMode = 'default',
  priceSize = 'xl',
  shouldShowPricePlaceholder,
  showFooterPricePlaceholder,
  isPriceBreakdownDisabled,
}) => {
  validateCustomerType(contractTemplate)

  const { estimatedConsumption, deliveryArea } = useGlobalStore((store) => store.housingInfo)
  const startDate = useGlobalStore((store) => store.checkoutData.delivery.startDate)
  const customerType = contractTemplate.customerType.toLocaleLowerCase() as CustomerType
  const selectedContract = useGlobalStore((state) => state.selectedContract)
  const { selectedAddons } = selectedContract[customerType]
  const { colors } = useTheme()
  const tPurchaseFlow = useTranslations('purchaseFlow')
  const tAriaLabels = useTranslations('ariaLabels')
  const selectedAddonsTariffNos = useMemo(
    () => getSelectedAddonsTariffNos(contractTemplate, selectedAddons),
    [contractTemplate, selectedAddons],
  )
  const data = useContractTemplateCostCalculator({
    contractTemplate,

    selectedAddonsTariffNos,
    area: deliveryArea,
    estimatedYearlyConsumption: estimatedConsumption,
    startDate,
  })
  const colorConfiguration = defineTheme(colors, theme)
  const defaultUnit = `${countryConfig.currencySign}/${tPurchaseFlow('monthUnit')}`

  const renderPricePlaceholder = (showExtraText = false) => (
    <Box display="flex" flexDirection="column">
      {showExtraText && (
        <ContentText size="s" color={colors.textLink} visibility="hidden">
          {tPurchaseFlow('priceTitle')}
        </ContentText>
      )}
      <ContentText
        size={priceSize}
        fontWeight={fontWeights.medium}
        color={colorConfiguration.price}
        data-testid="no-price"
      >
        <span>--</span>
        {` `}
        <span>{defaultUnit}</span>
      </ContentText>
      <ContentText
        size="xs"
        color={colorConfiguration.noPriceInfo}
        textWrap="word"
        data-testid="no-price-info"
      >
        {tPurchaseFlow('noPriceInfo')}
      </ContentText>
    </Box>
  )

  const getPriceString = (cost: CostDetails) => {
    const price = getPriceBaseOnCustomerType(cost, contractTemplate.customerType)

    if (price) {
      return `${price.toFixed(2)} ${cost.priceUnit}`
    }
  }

  if (!data) {
    if (shouldShowPricePlaceholder) {
      return renderPricePlaceholder(true)
    }

    if (showFooterPricePlaceholder) {
      return renderPricePlaceholder()
    }

    return null
  }

  const {
    estimatedMonthlyCostWithDiscounts: withDiscount,
    estimatedMonthlyCost: withoutDiscount,
    energyPriceSumWithDiscounts,
  } = data

  const monthlyFinalString = getPriceString(withDiscount)
  const monthlyFullString =
    withDiscount.priceExclVat === withoutDiscount.priceExclVat
      ? undefined
      : getPriceString(withoutDiscount)
  const electricityPriceString = getPriceString(energyPriceSumWithDiscounts)

  return (
    <Box>
      {mode === 'full' && (
        <PriceBreakdownInfoIcon
          mode={priceBreakdownMode}
          theme={theme}
          contractTemplate={contractTemplate}
          campaignConfiguration={campaignConfiguration}
          contractProduct={contractProduct}
          isDisabled={isPriceBreakdownDisabled}
        >
          <ContentText size="s" color="inherit">
            {tPurchaseFlow('priceTitle')}
          </ContentText>
        </PriceBreakdownInfoIcon>
      )}
      <ContentText
        size={priceSize}
        fontWeight={fontWeights.medium}
        color={colorConfiguration.price}
        aria-label={monthlyFullString ? tAriaLabels('discountPrice') : tAriaLabels('price')}
        data-testid="contract-price"
      >
        {monthlyFinalString}
      </ContentText>

      {monthlyFullString && (
        <CrossedOutText
          aria-label={tAriaLabels('originalPrice')}
          size="s"
          data-testid="crossed-out-price"
          color={colorConfiguration.crossedPrice}
        >
          {monthlyFullString}
        </CrossedOutText>
      )}
      <ContentText size="xs" data-testid="price-per-KW" color={colorConfiguration.detailedPrice}>
        {tPurchaseFlow('priceEnergyTitle')}: {electricityPriceString}
      </ContentText>
    </Box>
  )
}

const CrossedOutText = styled(ContentText)`
  text-decoration: line-through;
`
