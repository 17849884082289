'use client'

import { Fragment, type MouseEvent } from 'react'

import {
  Box,
  ContentText,
  IconCheck,
  Toggle,
  fontSizes,
  fontWeights,
  spacing,
  useToggle,
} from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import { useTheme } from '@/shared/hooks/useTheme'
import type { SelectedAddons } from '@/shared/store/slices/selectedContractSlice'
import type { AddonItem } from '@/shared/utils/addonsUtils'

import { HourlyMeasurementModal } from '../HourlyMeasurementModal'

export type IconColorVariant = 'primary' | 'secondary'

export type AddonSectionProps = {
  /**
   * Addon items which should be presented
   */
  addons: AddonItem[]
  /**
   * VoidFunction triggered whenver one of the toggles change state
   */
  onChange: (addon: AddonItem) => void
  /**
   * List of pre selected addons tariffNos
   */
  selectedAddons?: SelectedAddons
}

/**
 * Section which present bundled or pre-selected Addons
 */
export const AddonSection = ({ addons, onChange, selectedAddons }: AddonSectionProps) => {
  const theme = useTheme()
  const [isHourlyMeasurementModalOpened, toggleIsHourlyMeasurementModalOpened] = useToggle(false)

  const handleHourlyMeasurementModalConfirm = (addon: AddonItem) => {
    onChange(addon)
    toggleIsHourlyMeasurementModalOpened()
  }

  const handleClick = (e: MouseEvent, addon: AddonItem) => {
    e.preventDefault()
    const isSelected = selectedAddons?.[addon.addonType]?.value === addon.tariffNo
    if (!addon.isBundled) {
      //Open dialog if hourly measurement addon is not selected, otherwise deselect it immediately
      if (addon.isHourlyMeasurementAddon && !isSelected) {
        toggleIsHourlyMeasurementModalOpened()
      } else {
        onChange(addon)
      }
    }
  }

  return (
    <Box
      style={{
        background: theme.colors.backgroundPositive,
      }}
      display="flex"
      flexDirection="column"
      gap={{ default: spacing.xxs, s: spacing.xs }}
      p={{ default: spacing.xxs, s: spacing.xs }}
      borderRadius={`0  0 ${spacing.xxxs} ${spacing.xxxs}`}
    >
      {addons.map((addon) => (
        <Fragment key={addon.tariffNo}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            onClick={(e) => handleClick(e, addon)}
            cursor="pointer"
          >
            <Box display="flex" gap={spacing.xs}>
              {addon.icon && <Icon size={48} icon={addon.icon} color={theme.colors.textPositive} />}
              <Box display="flex" flexDirection="column" justifyContent="center">
                <ContentText fontSize={fontSizes.s} color={theme.colors.textPositive}>
                  {addon.primaryText}
                </ContentText>
                {!addon.isHourlyMeasurementAddon && (
                  <ContentText
                    fontSize={fontSizes.s}
                    color={theme.colors.textPositive}
                    fontWeight={fontWeights.medium}
                  >
                    {addon.secondaryText}
                  </ContentText>
                )}
              </Box>
            </Box>
            {addon.isBundled ? (
              <Icon icon={IconCheck} />
            ) : (
              <Toggle
                data-testid={`addon-toggle-${addon.tariffNo}`}
                color={theme.colors.textPositive}
                checked={selectedAddons?.[addon.addonType]?.value === addon.tariffNo}
                onChange={(e) => e.preventDefault()}
                aria-label={addon.primaryText}
              />
            )}
          </Box>
          {addon.isHourlyMeasurementAddon && (
            <HourlyMeasurementModal
              isOpened={isHourlyMeasurementModalOpened}
              onCancel={toggleIsHourlyMeasurementModalOpened}
              onConfirm={() => handleHourlyMeasurementModalConfirm(addon)}
              content={addon.additionalInformation}
            />
          )}
        </Fragment>
      ))}
    </Box>
  )
}
