import { useTranslations } from 'next-intl'
import styled from 'styled-components'

import type { FontSize } from '@fortum/elemental-ui'
import { ContentText, fontSizes, fontWeights, spacing } from '@fortum/elemental-ui'

import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import type { TariffElement } from '@/shared/graphql/schema/commonBackend/graphql'
import { useTheme } from '@/shared/hooks/useTheme'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'
import { isMonthlyFeeDiscountElement } from '@/shared/utils/tariffElementUtils'

import { defineTheme, formatFinnishPrice, getDiscountedPrice } from '../utils'

export type MonthlyFeeProps = {
  tariffElement: TariffElement
  contractTemplate: EnrichedContractTemplate
  renderLabel?: boolean
  priceSize?: FontSize
  theme?: 'light' | 'dark'
}

export const MonthlyFee = ({
  tariffElement,
  contractTemplate,
  renderLabel = true,
  priceSize = 'xl',
  theme = 'light',
}: MonthlyFeeProps) => {
  const tPurchaseFlow = useTranslations('purchaseFlow')
  const tAriaLabels = useTranslations('ariaLabels')
  const { colors } = useTheme()
  const colorConfiguration = defineTheme(colors, theme)

  const discountElementForMonthlyFee = contractTemplate.discountElements.find((element) =>
    isMonthlyFeeDiscountElement(element.type),
  )

  const monthlyPrice = tariffElement
    ? getPriceBaseOnCustomerType(tariffElement?.prices?.[0], contractTemplate.customerType)
    : null

  const discountedMonthlyPrice = monthlyPrice
    ? getDiscountedPrice(monthlyPrice, discountElementForMonthlyFee, contractTemplate.customerType)
    : 0

  const formattedMonthlyPrice = monthlyPrice ? formatFinnishPrice(monthlyPrice) : null

  const formattedDiscountedMonthlyPrice = discountedMonthlyPrice
    ? formatFinnishPrice(discountedMonthlyPrice)
    : 0

  const monthlyPriceToDisplay = discountElementForMonthlyFee
    ? formattedDiscountedMonthlyPrice
    : formattedMonthlyPrice

  return (
    <>
      {renderLabel && (
        <ContentText size="s" color={colorConfiguration.crossedPrice} mt={spacing.xxxs}>
          {tPurchaseFlow('monthlyFee')}
        </ContentText>
      )}
      <ContentText
        size={priceSize}
        fontWeight={fontWeights.medium}
        color={colorConfiguration.price}
        aria-label={
          discountElementForMonthlyFee ? tAriaLabels('discountPrice') : tAriaLabels('price')
        }
        data-testid="contract-price"
      >
        {`${monthlyPriceToDisplay} ${tariffElement.priceUnit}`}
      </ContentText>
      {discountElementForMonthlyFee && formattedMonthlyPrice !== monthlyPriceToDisplay && (
        <>
          <CrossedOutText mt="0.25rem" aria-label={tAriaLabels('originalPrice')}>
            {`${formattedMonthlyPrice} ${tariffElement.priceUnit}`}
          </CrossedOutText>
          {discountElementForMonthlyFee.originalPriceDescription && (
            <ContentText fontSize={fontSizes.xs} color={colors?.textPositive} textWrap="word">
              {discountElementForMonthlyFee.originalPriceDescription}
            </ContentText>
          )}
        </>
      )}
    </>
  )
}

const CrossedOutText = styled(ContentText)`
  text-decoration: line-through;
`
