'use client'

import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import { StyledLink } from '@/shared/components/StyledLink'
import { useTheme } from '@/shared/hooks/useTheme'
import type { Theme } from '@/shared/providers/theme'
import { ProductJsonLd } from '@/shared/sections/ContractListSection/ProductJsonLd'
import type {
  EnrichedAvailableAddon,
  EnrichedContractTemplate,
} from '@/shared/services/campaignDataResolver'
import type { SelectedAddons } from '@/shared/store/slices/selectedContractSlice'
import type { AddonItem } from '@/shared/utils/addonsUtils'
import type { CosColor } from '@/shared/utils/colors'

import { BulletList, ListItem } from '../../BulletList'
import { CardContainer, type CardContainerProps } from '../../CardContainer'
import { NotificationBanner } from '../../NotificationBanner'
import type { NotificationType } from '../../NotificationBanner'
import { PricePortal } from '../../PricePortal'
import { StyledHeading } from '../../StyledHeading'
import { AddonSection } from '../shared/AddonSection'

type Notification = {
  type: NotificationType
  text: string
}

export type ContractContent = {
  name: string
  description?: string
  uspList?: string[]
  addons?: EnrichedAvailableAddon[]
  currency?: string
  tariffElements?: EnrichedContractTemplate['tariffElements']
  customerType?: EnrichedContractTemplate['customerType']
  notifications?: Notification[]
}

export type ContractCardProps = {
  /**
   * Primary button component
   */
  primaryButton?: JSX.Element | null

  /**
   * Secondary button text
   */
  secondaryButtonText?: string

  /**
   * Secondary button href
   */
  secondaryButtonHref?: string | null

  /**
   * Badge configuration eg.:
   */
  cardBadge?: CardContainerProps['cardBadge'] | null

  /**
   * Contract content data. In most cases source of that data should be Contentful
   */
  contractContent: ContractContent

  /**
   * Render prop for a price component
   */
  priceComponent?: JSX.Element

  /**
   * List of addons bundled into contract or set of preselected addons (eg. base on some pre-filled url params)
   */
  bundledOrPreselectedAddons?: AddonItem[]

  /**
   * Callback triggered after addon section toggle have changed state
   */
  onSelectedAddonsChange?: (addon: AddonItem) => void

  /**
   * List of selected Addons tariffNos
   */
  selectedAddons?: SelectedAddons

  /**
   * Bool to toggle darkOnLight theme for recommended card
   */
  isRecommended?: boolean

  /**
   * Dims contract page, should be used together with disabling purchase button
   */
  isDisabled?: boolean
}

/**
 * Default component designed for contract data presentation.
 */
export const ContractCard = ({
  contractContent: {
    name,
    description,
    uspList,
    addons,
    tariffElements,
    currency,
    customerType,
    notifications,
  },
  primaryButton = null,
  secondaryButtonText: readMoreButtonText,
  secondaryButtonHref,
  cardBadge,
  priceComponent,
  bundledOrPreselectedAddons,
  onSelectedAddonsChange,
  selectedAddons,
  isRecommended,
  isDisabled,
}: ContractCardProps) => {
  const shouldShowAddonSection = bundledOrPreselectedAddons?.length && onSelectedAddonsChange
  const isReadMoreButtonVisible = readMoreButtonText || secondaryButtonHref
  const productJsonLdProps = { name, addons, tariffElements, currency, customerType }

  const theme = useTheme()

  const modifiedColors: CosColor = isRecommended
    ? {
        ...theme.colors,
        backgroundPrimary: theme.colors.backgroundLightOnDarkGradient,
        textPrimary: theme.colors.textLightOnDark,
        textSecondary: theme.colors.textLightOnDark,
        textPositive: theme.colors.textLightOnDarkAccent,
        textLarge: theme.colors.textLightOnDark,
        brandPrimary: theme.colors.textLightOnDark,
        backgroundPositive: theme.colors.backgroundLightOnDark,
      }
    : theme.colors

  const modifiedCardBadge = isRecommended
    ? {
        cardBadge,
        colors: {
          ...modifiedColors,
        },
      }
    : { cardBadge }

  const modifiedTheme: Theme = isRecommended
    ? {
        ...theme,
        colors: {
          ...theme.colors,
          ...modifiedColors,
        },
      }
    : theme

  return (
    <CardContainer
      width="100%"
      colors={modifiedTheme}
      {...modifiedCardBadge}
      isDisabled={isDisabled}
      testId="contract-product-card"
    >
      <Box width="100%" height="100%" display="flex" flexDirection="column">
        <Box
          p={{ default: ` ${spacing.xs} ${spacing.xxs}`, s: `${spacing.s} ${spacing.xs}` }}
          background={modifiedTheme.colors.backgroundPrimary}
          display="flex"
          height="100%"
          borderRadius={
            shouldShowAddonSection ? `${spacing.xxxs} ${spacing.xxxs} 0  0` : spacing.xxxs
          }
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={{ default: spacing.xxs, s: spacing.xs }}
            justifyContent="space-between"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={{ default: spacing.xxs, s: spacing.xs }}
            >
              <Box
                display="flex"
                flexDirection="column"
                gap={{ default: spacing.xxs, s: spacing.xs }}
              >
                <Box display="flex" textAlign="center" justifyContent="center">
                  <StyledHeading
                    level={4}
                    data-testid="contract-product-card-title"
                    color={modifiedTheme.colors.textLarge}
                  >
                    {name}
                  </StyledHeading>
                </Box>
                {description && (
                  <ContentText textColor={modifiedTheme.colors.textPrimary}>
                    {description}
                  </ContentText>
                )}
              </Box>

              {Boolean(uspList?.length) && (
                <BulletList
                  colors={{
                    textPrimary: modifiedTheme.colors.textPrimary,
                    textPositive: modifiedTheme.colors.textPositive,
                  }}
                >
                  {uspList?.map((usp) => <ListItem key={usp}>{usp}</ListItem>)}
                </BulletList>
              )}
            </Box>

            {(primaryButton || isReadMoreButtonVisible) && (
              <Box
                display="flex"
                flexDirection="column"
                gap={spacing.xxs}
                justifyContent="flex-end"
                alignSelf="stretch"
              >
                {priceComponent || null}
                <PricePortal textAlign="left" theme={isRecommended ? 'dark' : 'light'} />
                <Box display="flex" flexDirection="column" alignItems="center" gap={spacing.xxs}>
                  {Boolean(notifications?.length) && (
                    <Box width="100%">
                      {notifications?.map((notification) => (
                        <NotificationBanner
                          key={notification.text}
                          type={notification.type}
                          message={notification.text}
                          shouldHideCloseButton
                        />
                      ))}
                    </Box>
                  )}

                  {primaryButton}
                  {isReadMoreButtonVisible && secondaryButtonHref && (
                    <StyledLink
                      data-testid="read-more-about-contract"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      alignSelf="stretch"
                      color={modifiedTheme.colors.brandPrimary}
                      href={secondaryButtonHref}
                    >
                      {readMoreButtonText}
                    </StyledLink>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {shouldShowAddonSection ? (
          <AddonSection
            addons={bundledOrPreselectedAddons}
            onChange={onSelectedAddonsChange}
            selectedAddons={selectedAddons}
          />
        ) : null}
      </Box>
      <ProductJsonLd {...productJsonLdProps} />
    </CardContainer>
  )
}
